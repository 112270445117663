/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// exports.onRouteUpdate = ({ location, prevLocation }) => {}

import * as React from "react"

export const onRouteUpdate = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "gatsby-route-change",
    })
  }

  if (typeof document !== "undefined") {
    const existingScript = document.getElementById("instaEmbed")

    if (!existingScript) {
      const script = document.createElement("script")
      script.src =
        "//www.instagram.com/static/bundles/metro/EmbedSDK.js/33cd2c5d5d59.js"
      script.id = "instaEmbed"
      document.body.appendChild(script)

      script.onload = () => {
        window.instgrm.Embeds.process()
      }
    }

    if (existingScript) {
      window.instgrm.Embeds.process()
    }
  }
}
